<script setup lang="ts"></script>

<template>
    <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.19808 6.65875C4.6092 7.11375 5.3908 7.11375 5.80192 6.65875L7.77303 4.47726C8.31096 3.88191 7.83222 3 6.97112 3L3.02889 3C2.16778 3 1.68904 3.88191 2.22697 4.47726L4.19808 6.65875Z"
            fill="currentColor"
        />
    </svg>
</template>
